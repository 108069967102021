<template>
  <a @click.prevent.stop="showModal = true">
    {{ $t("generic.remove") }}
    <modal-dialog
      :show="showModal"
      @close="showModal = false"
      target-class="modal__card--mini"
    >
      <div class="card-wizard">
        <div class="card-wizard__title">
          <h3 class="header-3 w-70">
            {{ title }}
          </h3>
          <p v-if="subtitle" v-text="subtitle"></p>
        </div>
        <div class="card-wizard__actions card-wizard__actions--mt-0">
          <loading-button v-if="loading"></loading-button>
          <button
            v-else
            class="button button--primary button--block"
            @click="remove()"
          >
            {{ $t("generic.remove") }}
          </button>
          <button
            class="button button--secondary mar-t-fs-smaller button--block"
            @click="showModal = false"
          >
            {{ $t("generic.cancel") }}
          </button>
        </div>
      </div>
    </modal-dialog>
  </a>
</template>

<script>
import ModalDialog from "../../../components/ui/ModalDialog";
import LoadingButton from "../../../components/LoadingButton";
import ProductDelete from "./Mixins/ProductDelete";

export default {
  name: "DeleteProduct",
  components: {
    "modal-dialog": ModalDialog,
    "loading-button": LoadingButton,
  },
  mixins: [ProductDelete],
  props: {
    product: {
      type: Object,
      required: true,
    },
    listId : {
      type: Number,
      required: true,
    }
  },
  methods: {
    async remove() {
      this.loading = true;
      try {
        if (this.product.isFree) {
          await this.$store.dispatch("removeProductFromList",{ listId: this.listId, product: this.product});
        } else {
          await this.$store.dispatch("removeListProduct",{ productId: this.product.listProductId});
        }

        // HACK per donar temps al Vue watcher del show a tancar correctament el modal
        // AIXÔ ÉS PER QUE LA CARD DEL FUND DESAPAREIX AL SER UNA OPERACIÓ ESBORRAR
        this.$emit('deleted')
        this.$sleep(() => {
          this.$store.dispatch("getOwnerList");
        }, 100);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
      this.showModal = false;
    },
  },
};
</script>
